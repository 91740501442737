import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "footer-copyright"
};
import { watch, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 's-footer',
  setup(__props) {
    const router = useRouter();
    const style = ref({});
    // 占位的高度
    const seatStyle = ref({});
    watch(() => router, () => {
      if (document.body.scrollHeight < window.screen.height) {
        style.value = {
          position: 'fixed',
          bottom: '0'
        };
        seatStyle.value = {
          height: '102px'
        };
      } else {
        style.value = {
          position: 'relative'
        };
        seatStyle.value = {
          height: '0'
        };
      }
    }, {
      immediate: true,
      deep: true
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "footer",
        style: _normalizeStyle(style.value)
      }, [_createElementVNode("div", _hoisted_1, "©" + _toDisplayString(new Date().getFullYear()) + "，厦门斯玛特思智能电气有限公司版权所有", 1), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "footer-info"
      }, " 电话：0592-6253630 地址：福建省厦门市湖里区火炬高新新丰三路2号二楼 ", -1))], 4), _createElementVNode("div", {
        style: _normalizeStyle(seatStyle.value)
      }, null, 4)], 64);
    };
  }
};