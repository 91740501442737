import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/images/glob/logo.jpg';
import _imports_1 from '@/assets/images/glob/img_cover.jpg';
const _hoisted_1 = {
  class: "container-xl header"
};
const _hoisted_2 = {
  class: "header-wrapper"
};
const _hoisted_3 = {
  class: "header-content"
};
const _hoisted_4 = {
  class: "d-md-none"
};
const _hoisted_5 = {
  class: "d-none d-md-flex menu-box"
};
const _hoisted_6 = ["onClick"];
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import smallMenu from './small-menu';
export default {
  __name: 's-header',
  setup(__props) {
    let current = ref(0);
    // 菜单列表
    const menuList = [{
      name: '首页',
      path: '/home'
    }, {
      name: '关于我们',
      path: '/about'
    }, {
      name: '资质荣誉',
      path: '/honors'
    }, {
      name: '解决方案',
      path: '/programme'
    }, {
      name: '专业售后',
      path: '/afterSales'
    }, {
      name: '联系我们',
      path: '/contact'
    }];

    // actived状态与路由进行匹配
    const router = useRouter();
    watch(() => router, newValue => {
      const path = newValue.currentRoute.value.fullPath;
      const findIndex = menuList.findIndex(item => item.path === path);
      if (findIndex !== -1) {
        current.value = findIndex;
      }
    }, {
      immediate: true,
      deep: true
    });
    const toPage = (path, index) => {
      current.value = index;
      router.push(path);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("a", {
        class: "logo-box",
        href: "#"
      }, [_createElementVNode("img", {
        class: "logo",
        src: _imports_0
      }), _createElementVNode("p", {
        class: "name"
      }, "厦门斯玛特思智能电气有限公司")], -1)), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(smallMenu), {
        menuList: menuList
      })]), _createElementVNode("div", _hoisted_5, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuList, (item, index) => {
        return _createElementVNode("div", {
          key: item.name,
          class: _normalizeClass(['menu-item', index === _unref(current) ? 'activited' : '']),
          onClick: $event => toPage(item.path, index)
        }, _toDisplayString(item.name), 11, _hoisted_6);
      }), 64))])])]), _cache[1] || (_cache[1] = _createElementVNode("img", {
        class: "cover object-fit-cover",
        src: _imports_1
      }, null, -1))]);
    };
  }
};